<template>
  <div class="kFormField">
    <label
      v-if="$slots.label || label"
      class="kFormField__label"
      :class="{'kFormField__label--required': required}"
    >
      <slot name="label">
        {{ label }}
        <span class="optional"
              v-if="optionalLabel">({{optionalLabel}})</span>
      </slot>
    </label>
    <div
      v-if="$slots.description || description"
      class="kFormField__description"
    >
      <slot name="description">
        {{ description }}
      </slot>
    </div>
    <validation-provider
      class="kFormField__inputWrapper"
      :rules="$attrs.rules"
      :name="name || label"
      v-slot="{ errors }"
      mode="eager"
    >
      <slot v-bind:errors="errors"></slot>
      <div v-if="errors.length"
         class="kFormField__errors">
        <slot name="errors">
          {{ errors[0] }}
        </slot>
      </div>
    </validation-provider>
  </div>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate/dist/vee-validate.full';
import { required } from 'vee-validate/dist/rules';
import { regexPatterns } from '@utils';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('url', {
  validate: (value) => regexPatterns.url.regex.test(value),
  message: 'Please enter a valid URL. The URL must start with either http:// or https://',
});

extend('email', {
  validate: (value) => regexPatterns.email.regex.test(value),
  message: 'Please use a valid email',
});

extend('profileEditPhone', {
  validate: (value) => regexPatterns.profilePhoneNumber.regex.test(value),
  message: 'Please enter a valid phone number. The phone number must be in the following format: +15555555555',
});

extend('mobileNumber', {
  validate: (value) => regexPatterns.mobileNumber.regex.test(value),
  message: 'Please enter a valid phone number. The phone number must be in the following format: 2222222222',
});

export default {
  name: 'k-form-field',
  components: {
    ValidationProvider,
  },

  props: {
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    name: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    optionalLabel: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
  .kFormField {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__label {
      position: relative;
      width: 100%;
      font-weight: 500;
      color: var(--gray-80);

      &--required::after {
        margin-left: var(--grid-unit);
        color: var(--coral-20);
        content: 'Required';
      }
    }

    &__description {
      margin-bottom: var(--grid-unit);
      color: var(--gray-60);
    }

    &__inputWrapper {
      align-self: stretch;
    }

    &__errors {
      color: var(--rust-50);
    }

    .optional {
      color: #616161;
      font-size: calc(var(--grid-unit) * 2);
      font-weight: 400;
    }
  }

  .kFormField + .kFormField {
    margin-top: calc(var(--grid-unit) * 2);
  }

</style>
